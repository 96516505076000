import { FC, useCallback, useEffect, useRef } from "react"
import clsx from "clsx"
import { Xmark } from "iconoir-react"

import ErrorWithData from "@/utils/ErrorWithData"
import createStyle from "@/utils/createStyle"

import ErrorUnwrapper from "@/components/ErrorUnwrapper"

import { LinkText } from "@/text"

import TOAST_STYLES from "./styles"
import { ManualToastProps, ToastProps } from "./types"

const TOAST_TIMEOUT = 6000

const ErrorText = createStyle(
  "div",
  "font-mono text-[0.75rem] leading-[1.3] tracking-tightest"
)

export const Toast: FC<ToastProps & { close: () => void }> = ({
  type = "success",
  className = "",
  close,
  content,
  ...props
}) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const abortAutoClose = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }, [])

  useEffect(() => {
    timeout.current = setTimeout(close, TOAST_TIMEOUT) as any as ReturnType<
      typeof setTimeout
    >
    return () => {
      clearTimeout(timeout.current)
    }
  }, [close])

  const manualToast: Partial<ManualToastProps> =
    typeof content === "object" ? (content as ManualToastProps) : {}
  const errorToast = content as ErrorWithData

  const toastTitle = manualToast?.message || (content as string)

  return (
    <div
      className={clsx(
        className,
        TOAST_STYLES[type],
        "rounded-md p-4",
        "flex items-start"
      )}
      {...props}
      role="alert"
      onMouseOver={abortAutoClose}
      onFocus={abortAutoClose}
    >
      <div className="flex w-0 flex-grow flex-col gap-1">
        <LinkText as="h2">{toastTitle}</LinkText>
        {"detail" in manualToast && <ErrorText>{manualToast.detail}</ErrorText>}
        {errorToast instanceof ErrorWithData && errorToast?.data && (
          <ErrorText>
            <ErrorUnwrapper error={errorToast.data} />
          </ErrorText>
        )}
      </div>
      <button type="button" className="ml-3 flex flex-shrink-0" onClick={close}>
        <span className="sr-only">Dismiss notification</span>
        <Xmark width="20" height="20" />
      </button>
    </div>
  )
}

export default Toast
