"use client"

import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react"
import { AnimatePresence, motion } from "framer-motion"

import Toast from "@/components/Toast"
import { ToastContent, ToastProps } from "@/components/Toast/types"

export const ToastContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  addToast: (content: ToastContent, type?: ToastProps["type"]) => {},
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

/**
 * Provides a toast notification system
 *
 * To use, import the context and call `addToast` with the content and type
 */
export const ToastContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<({ timestamp: number } & ToastProps)[]>(
    []
  )

  const addToast = useCallback(
    (content: ToastContent, type: ToastProps["type"] = "success") => {
      setToasts((t) => [
        ...t,
        { content, type, timestamp: Date.now() + t.length / 100 },
      ])
    },
    []
  )

  const removeToast = useCallback(
    (timestamp: number) => () => {
      setToasts((t) => t.filter((toast) => toast.timestamp !== timestamp))
    },
    []
  )

  const fields = useMemo(() => ({ addToast }), [addToast])

  return (
    <ToastContext.Provider value={fields}>
      {children}
      {toasts.length > 0 && (
        <div
          className="fixed right-0 top-6 z-[51] flex max-w-full flex-col gap-3 px-4 md:px-6"
          style={{ width: 500 }}
        >
          <AnimatePresence>
            {toasts.map(({ timestamp, ...toast }) => (
              <motion.div
                initial={{ x: "100%" }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "100%" }}
                key={timestamp}
              >
                <Toast {...toast} close={removeToast(timestamp)} />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}
    </ToastContext.Provider>
  )
}
export default ToastContext
