import createStyle from "./utils/createStyle"

export const Display0 = createStyle(
  "h1",
  "font-heading text-[5.25rem] font-black uppercase leading-[0.8] sm:text-[8rem] md:text-[9.125rem] lg:text-[12rem] xl:text-[15.625rem]"
) // 250px 192px 146px 128px 84px

export const Display1 = createStyle(
  "h1",
  "font-heading text-[5.125rem] font-black uppercase leading-[0.8] sm:text-[6.875rem] md:text-[8rem] lg:text-[9.125rem]"
) // 146px 128px 110px

export const Display2 = createStyle(
  "h2",
  "font-heading text-[5.125rem] font-black uppercase leading-[0.85] tracking-tight md:text-[6.25rem] lg:text-[7.5rem]"
) // 120px 100px 82px

export const Display3 = createStyle(
  "h3",
  "font-heading text-[4.7rem] font-black uppercase leading-[0.85] md:text-[5.75rem] lg:text-[6.875rem]"
) // 110px 92px 76px

export const Display4 = createStyle(
  "h4",
  "mb-8 font-heading text-[3.75rem] font-black uppercase leading-[0.85] tracking-tight last:mb-0 md:text-[4.75rem] lg:text-[5.75rem]"
) // 92px 76px 60px

export const Display5 = createStyle(
  "h5",
  "font-heading text-[3rem] font-black uppercase leading-[0.85] tracking-tight md:text-[3.625rem] lg:text-[4.375rem]"
) // 70px 58px 48px

export const Display6 = createStyle(
  "h5",
  "font-heading text-[3.5rem] font-black uppercase leading-[0.85] tracking-tight"
) // 56px

export const Display7 = createStyle(
  "h5",
  "font-heading text-[2.625rem] font-black uppercase leading-[0.85] tracking-tight"
) // 42px

export const Display8 = createStyle(
  "h5",
  "font-heading text-[1.75rem] font-black uppercase leading-[0.85] tracking-tight sm:text-[2.25rem]"
) // 36px 28px

export const H1 = createStyle(
  "h1",
  "font-sans text-[2.375rem] font-bold leading-[1] tracking-tightest md:text-[2.875rem] lg:text-[3.5rem]"
) // 56px 46px 38px

export const H2 = createStyle(
  "h2",
  "font-sans text-[2.25rem] font-medium leading-[1] tracking-tightest md:text-[2.625rem] lg:text-[3rem]"
) // 48px 42px 36px

export const H3 = createStyle(
  "h3",
  "font-sans text-[1.75rem] font-black leading-[1.2]"
) // 28px

export const H4 = createStyle(
  "h4",
  "font-sans text-[1.375rem] font-bold leading-[1] tracking-tightest"
) // 22px

export const H5 = createStyle(
  "h5",
  "font-sans text-[1.125rem] font-bold leading-[1] tracking-tightest"
) // 18px

export const Subheading1 = createStyle(
  "p",
  "font-mono text-[1.75rem] font-medium leading-[1] tracking-tightest"
) // 28px

export const Subheading2 = createStyle(
  "p",
  "font-mono text-[1.125rem] font-medium leading-[1.3] tracking-tightest"
) // 18px

// Keep in sync with body style in globals.css
export const Body1 = createStyle(
  "p",
  "font-sans text-[1rem] font-normal leading-[1.20] tracking-tightest"
) // 18px

export const Body2 = createStyle(
  "p",
  "font-sans text-[1.375rem] font-normal leading-[1.15] tracking-tightest"
) // 22px

// previously called UI 1, 2 & 3 in Figma
export const LinkText = createStyle(
  "p",
  "font-sans text-[1rem] font-normal leading-[1.2] tracking-tightest"
) // 16px

export const TagLabel = createStyle(
  "p",
  "font-mono text-[0.75rem] font-normal uppercase leading-[1.3] tracking-tightest"
) // 12px

export const Caption = createStyle(
  "p",
  "font-sans text-[0.75rem] font-normal leading-[1.3] tracking-tightest"
) // 12px

export const ReferenceText = createStyle(
  "p",
  "font-sans text-[0.875rem] font-normal leading-[1] tracking-tightest"
) // 14px

export const MetaText = createStyle(
  "p",
  "font-mono text-[0.875rem] font-medium leading-[1.3] tracking-tightest md:text-[1rem] lg:text-[1.125rem]"
) // 18px 16px 14px
