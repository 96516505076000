import { HTMLAttributes, ReactNode, isValidElement } from "react"
import clsx from "clsx"
import ErrorWithData from "@/utils/ErrorWithData"

// For forms with nested objects, Django returns nested error messages, this should unpack them
const ErrorPathUnwrapper = ({
  path,
  message,
}: {
  path: string
  message: string | ReactNode | string[] | Record<string, any>
}) => {
  if (!message) {
    return null
  }

  const fieldRef: HTMLInputElement | null =
    (typeof window !== "undefined" &&
      document.querySelector(`[name="${path}"]`)) ||
    null
  if (path === "non_field_errors") {
    return <li>{(message as string[]).join("\n")}</li>
  }

  if (typeof message === "string" || isValidElement(message)) {
    return <li>{message}</li>
  }

  if (Array.isArray(message)) {
    return (
      <li>
        Error {fieldRef ? "with field" : "on field"}{" "}
        {fieldRef ? (
          <button
            type="button"
            className="underline"
            onClick={() => {
              fieldRef.focus()
              fieldRef.scrollIntoView({ block: "center" })
            }}
          >
            &ldquo;{path}&rdquo;
          </button>
        ) : (
          path
        )}
        : {message.join("\n")}
      </li>
    )
  }

  if (typeof message === "object") {
    return (
      <>
        {Object.entries(message as Record<string, any>).map(([k, v]) => (
          <ErrorPathUnwrapper
            key={["error", "children", path, k].join("_")}
            path={`${path}.${k}`}
            message={v}
          />
        ))}
      </>
    )
  }

  return <li>{JSON.stringify(message, null, 2)}</li>
}

const FILTERED_KEYS = ["response", "url"]

export const ErrorUnwrapper = ({
  error,
  className = "",
  ...props
}: { error: ErrorWithData["data"] } & HTMLAttributes<
  HTMLDivElement | HTMLUListElement
>) => {
  if (!error) {
    return null
  }

  if (typeof error === "string") {
    return (
      <span className={className} {...props}>
        {error}
      </span>
    )
  }

  if ("detail" in error) {
    return (
      <span className={clsx(className)} {...props}>
        {error.detail}
      </span>
    )
  }

  const visibleErrors: Record<string, any> = Object.fromEntries(
    Object.entries(error).filter(([k]) => !FILTERED_KEYS.includes(k))
  )

  if (Object.keys(visibleErrors).length > 0) {
    return (
      <ul
        className={clsx(
          Object.keys(visibleErrors).length > 1 && "list-disc pl-3",
          className
        )}
        {...props}
      >
        {Object.entries(visibleErrors ?? {}).map(([path, message]) => (
          <ErrorPathUnwrapper key={path} path={path} message={message} />
        ))}
      </ul>
    )
  }
  if ("response" in error) {
    return (
      <ul className={clsx("list-disc pl-3", className)} {...props}>
        <li>Unhandled error</li>
        <li>Response code: {error.response.status}</li>
        <li>Response type: {error.response.statusText}</li>
      </ul>
    )
  }

  if (error) {
    return (
      <div {...props} className={clsx(className, "whitespace-pre-wrap")}>
        {JSON.stringify(error, null, 2)}
      </div>
    )
  }

  return null
}

export default ErrorUnwrapper
